<template>
  <v-app>
    <v-app-bar app color="primary" dark >

      <div class="d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="170"
        />
      </div>

      <v-spacer></v-spacer>

      <span class="mr-2">{{version}}</span>

    </v-app-bar>

    <v-main>
      <Editor v-model="loading" />
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import Editor from '@/components/Editor';

export default {
  name: 'App',

  components: {
    Editor,
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    version() { return (process.env.VUE_APP_VERSION || 'dev'); }
  }

};
</script>
