<template>

  <div class="d-flex flex-column">

    <div class="d-flex ma-2">
      <div class="text-h4" v-if="journey">
        <v-icon>mdi-notebook</v-icon>
        <span class="text-capitalize">{{journey.name}}</span>
      </div>
      <v-spacer />
      <div><v-btn :color="(showProperties ? 'primary' : 'secondary')" @click="showProperties = !showProperties" class="mr-2"><v-icon>mdi-archive-outline</v-icon></v-btn></div>
      <div><v-btn color="primary" @click="save" :disabled="!saved">{{ (saved ? 'Save' : 'Saving' ) }}</v-btn></div>
    </div>

    <div class="d-flex mx-1 my-2">

      <div style="width: 50px;" class="mr-2 text-center">
        <PanelIcon type="annotation" :dragstart="dragStart" color="#FFD505" />
        <PanelIcon type="mock" :dragstart="dragStart" />
        <v-divider class="mb-1" />
        <PanelIcon type="conditional" :dragstart="dragStart" />
        <PanelIcon type="split" :dragstart="dragStart" />
        <PanelIcon type="join" :dragstart="dragStart" />
        <PanelIcon type="triggerProcess" :dragstart="dragStart" />
        <PanelIcon type="subProcess" :dragstart="dragStart" />
        <v-divider class="mb-1" />
        <PanelIcon type="event" :dragstart="dragStart" />
        <PanelIcon type="delay" :dragstart="dragStart" />
        <PanelIcon type="userInteraction" :dragstart="dragStart" />
        <PanelIcon type="review" :dragstart="dragStart" />
        <v-divider class="mb-1" />
        <PanelIcon type="code" :dragstart="dragStart" />
        <PanelIcon type="apiCall" :dragstart="dragStart" />
        <PanelIcon type="setVariable" :dragstart="dragStart" />
        <PanelIcon type="textMessage" :dragstart="dragStart" />
        <v-divider class="mb-1" />
        <PanelIcon type="error" :dragstart="dragStart" />
        <PanelIcon type="timeout" :dragstart="dragStart" />
        <PanelIcon type="stop" :dragstart="dragStart" />
      </div>

      <v-card id="drawflow" elevation="2" outlined @drop="drop" @dragover="allowDrop" />

      <div style="width:400px;" class="ml-2" v-if="showProperties">
        <Properties :uuid="nodeUuid" :journey="journey" v-if="nodeUuid" />
        <Journey v-model="journey" v-if="!nodeUuid && journey" />
      </div>

    </div>

    <div><SummaryProperties :uuid="nodeUuid" :journey="journey" v-if="nodeUuid" /></div>

  </div>


</template>

<script>
  import typeIcons from '@/mixins/typeIcons';
  import typeColours from '@/mixins/typeColours';
  import typeDescriptions from '@/mixins/typeDescriptions';
  import apiCalls from '@/mixins/apiCalls';

  import Vue from 'vue'
  import Drawflow from 'drawflow'
  import { v4 as uuidv4 } from 'uuid';

  import Node from '@/components/Node.vue'
  import Properties from '@/components/Properties.vue'
  import Journey from '@/components/Journey.vue'
  import SummaryProperties from '@/components/SummaryProperties.vue'
  import PanelIcon from '@/components/PanelIcon.vue'

  import styleDrawflow from 'drawflow/dist/drawflow.min.css'
  import editorCss from '@/components/editor.css';

  export default {

    components: { Properties, Journey, SummaryProperties, PanelIcon },

    props: { value: { type: Boolean, required: true } },

    data: () => ({

      journey: undefined,

      nodeUuid: undefined,
      node: undefined,

      editor: null,
      mobile_last_move: null,
      mobile_item_selec: '',

      saved: true,

      showProperties: true,

    }),

    computed: {
      journeyId() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return parseInt(params.id);
      },
      nodes() { return this.$store.getters.nodes; },
    },

    watch: {
      nodeUuid: {
        immediate: true,
        handler(to) {
          if (to) {
            this.node = this.findNode(to);
          } else {
            this.node = undefined;
          }
        },
      },
    },

    mounted() {
      
      // setup editor
      let _this = this;
      const id = document.getElementById("drawflow");
      this.editor = new Drawflow(id, Vue);
      this.editor.useuuid = true;

      // this block makes lines straight
      // this.editor.curvature = 0;
      // this.editor.reroute_curvature_start_end = 0;
      // this.editor.reroute_curvature = 0;
      // this.editor.createCurvature = function(start_pos_x, start_pos_y, end_pos_x, end_pos_y, curvature_value) {
      //   var center_x = ((end_pos_x - start_pos_x)/2)+start_pos_x;
      //   return ' M ' + start_pos_x + ' ' + start_pos_y + ' L '+ center_x +' ' +  start_pos_y  + ' L ' + center_x + ' ' +  end_pos_y  + ' L ' + end_pos_x + ' ' + end_pos_y;
      // }

      this.editor.on('nodeSelected', function(id) {
        const nodeInfo = _this.editor.getNodeFromId(id);
        _this.nodeUuid = nodeInfo.data.uuid;
      });

      this.editor.on('nodeUnselected', function() { _this.nodeUuid = undefined; });

      this.editor.on('connectionCreated', function(info) {

        // console.log(_this.editor.getNodeFromId(info.input_id).data.uuid);

        const input = _this.editor.getNodeFromId(info.input_id);
        const output = _this.editor.getNodeFromId(info.output_id);

        let inputNode = _this.findNode(input.data.uuid)
        let outputNode = _this.findNode(output.data.uuid)

        if (!inputNode.inputs) { Vue.set(inputNode, 'inputs', []); }
        if (!outputNode.outputs) { Vue.set(outputNode, 'outputs', []); }

        inputNode.inputs.push(outputNode.uuid);
        outputNode.outputs.push(inputNode.uuid);

        // if (nodeInfo.name!=='join') {
        //   const connections = nodeInfo.inputs[info.input_class].connections.length;
        //   if(connections > 1) {
        //     const removeConnectionInfo = nodeInfo.inputs[info.input_class].connections[connections-1];
        //     _this.editor.removeSingleConnection(removeConnectionInfo.node, info.input_id, removeConnectionInfo.input, info.input_class);
        //   }
        // }
      });
      this.editor.on('connectionRemoved', function(info) {

        const input = _this.editor.getNodeFromId(info.input_id);
        const output = _this.editor.getNodeFromId(info.output_id);

        let inputNode = _this.findNode(input.data.uuid);
        let outputNode = _this.findNode(output.data.uuid);

        // should not be required
        if (!inputNode.inputs) { Vue.set(inputNode, 'inputs', []); }
        if (!outputNode.outputs) { Vue.set(outputNode, 'outputs', []); }

        let inputs = inputNode.inputs;
        let outputs = outputNode.outputs;

        let i = inputs.findIndex( e => e===outputNode.uuid );
        let o = outputs.findIndex( e => e===inputNode.uuid );

        if (i > -1) { inputs.splice(i, 1); }
        if (o > -1) { outputs.splice(o, 1); }

        Vue.set(inputNode, 'inputs', inputs);
        Vue.set(outputNode, 'outputs', outputs);

      });
      // this.editor.on("nodeCreated", function(uuid) {
      //   const nodeInfo = _this.editor.getNodeFromId(uuid);
      //   // _this.nodes.push({ uuid: uuid, type: nodeInfo.name });
      //   _this.$store.dispatch('addNode', { uuid: uuid, type: nodeInfo.name });
      // });

      // this.reroute_fix_curvature = true;
      // this.editor.curvature = 0.5; // Default no reroute
      // this.editor.reroute_curvature_start_end = 0.5;
      // this.editor.reroute_curvature = 0.5; // (Other reroutes) 
      // this.editor.createCurvature = function(start_pos_x, start_pos_y, end_pos_x, end_pos_y, curvature_value) {
      //   var center_x = ((end_pos_x - start_pos_x)/2)+start_pos_x;
      //   return ' M ' + start_pos_x + ' ' + start_pos_y + ' L '+ center_x +' ' +  start_pos_y  + ' L ' + center_x + ' ' +  end_pos_y  + ' L ' + end_pos_x + ' ' + end_pos_y;
      // }

      this.editor.start();

      let elements = document.getElementsByClassName('drag-drawflow');
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('touchend', this.drop, false);
        elements[i].addEventListener('touchmove', this.positionMobile, false);
        elements[i].addEventListener('touchstart', this.dragStart, false );
      }

      this.loadJourney();

    },

    methods: {

      findNode(uuid) { return this.nodes.find( e => e.uuid==uuid ); },

      positionMobile(ev) { this.mobile_last_move = ev; },
      allowDrop(ev) { ev.preventDefault(); },

      dragStart(ev) {
        if (ev.type === "touchstart") {
          this.mobile_item_selec = ev.target.closest(".drag-drawflow").getAttribute('data-node');
        } else {
          ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
        }
      },

      drop(ev) {
        if (ev.type === "touchend") {
          let parentdrawflow = document.elementFromPoint( mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY).closest("#drawflow");
          if(parentdrawflow != null) {
            this.addNodeToDrawFlow(this.mobile_item_selec, this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY);
          }
          this.mobile_item_selec = '';
        } else {
          ev.preventDefault();
          let data = ev.dataTransfer.getData("node");
          this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
        }

      },

      addNodeToDrawFlow(name, drop_x, drop_y) {

        if (this.editor.editor_mode === 'fixed') { return false; }

        let precanvas = this.editor.precanvas;
        let zoom = this.editor.zoom;

        let pos_x = drop_x * ( precanvas.clientWidth / (precanvas.clientWidth * zoom)) - (precanvas.getBoundingClientRect().x * ( precanvas.clientWidth / (precanvas.clientWidth * zoom)));
        let pos_y = drop_y * ( precanvas.clientHeight / (precanvas.clientHeight * zoom)) - (precanvas.getBoundingClientRect().y * ( precanvas.clientHeight / (precanvas.clientHeight * zoom)));

        const uuid = uuidv4();
        const props = { 'uuid': uuid, type: name };

        this.editor.registerNode(uuid, Node, props, {});
        let inputs = 1;
        let outputs = 1;
        if (name==='start') { inputs = 0; }
        if (name==='stop') { outputs = 0; }
        if (name==='conditional') { outputs = 2; }
        if (name==='annotation') { inputs = 0; outputs = 0; }
        this.editor.addNode(name, inputs, outputs, pos_x, pos_y, name + 'Class', { uuid: uuid }, uuid, 'vue');

        this.$store.dispatch('addNode', props);

      },

      loadJourney() {
        this.lookup('load', { id: this.journeyId }).then(e => this.initFlow(e));
      },

      initFlow(data) {
        this.journey = data.journey;
        let nodes = data.nodes || [];
        this.$store.dispatch('nodes', nodes);
        if (nodes.length==0) {
          this.addNodeToDrawFlow('start', 150, 150);
          this.addNodeToDrawFlow('stop', 450, 450);
        } else {
          // console.log(data.flow);
          data.nodes.forEach(e => {
            this.editor.registerNode(e.uuid, Node, { 'uuid': e.uuid, type: e.type }, {});
          });
          this.editor.import(data.flow);
        }
        this.$emit('input', false);
      },

      save() {
        this.saved = false;
        let data = {};
        data.journey = this.journey;
        data.nodes = this.nodes;
        data.flow = this.editor.export();
        this.post('save', { id: this.journeyId }, data).then(e => this.saved=true );
      },
    },

    mixins: [typeIcons, typeColours, typeDescriptions, apiCalls],

  }
</script>

<style scoped>
#drawflow {
  width: 100%;
  height: 80vh;
  overflow: auto;
}
</style>
