<template>
  <div>
    <v-textarea v-model="node.code" label="Ruby Code" style="font-family: monospace, monospace; font-size: 12px;" />

    <v-chip class="ma-2" :color="colour(n.type)" label v-for="n in outputs" v-if="outputs.length>0" >
      <v-icon left>{{icon(n.type)}}</v-icon>
      {{n.name || n.type}}
    </v-chip>

    <v-alert type="error" dense v-if="!valid">
      Return value must match outputs.
      <span v-if="outputs.length===0">No outputs are defined.</span>
    </v-alert>

  </div>
</template>

<script>
import typeIcons from '@/mixins/typeIcons';
import typeColours from '@/mixins/typeColours';

export default {

  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  data: () => ({
    help: true,
  }),

  computed: {
    nodes() { return this.$store.getters.nodes; },
    valid() {
      let v = ( this.outputs.length>0 );
      return v;
    },
    outputs() { return (this.node.outputs || []).map( e => this.findNode(e) ); },
  },

  methods: {
    findNode(uuid) { return this.nodes.find( e => e.uuid==uuid ); },
    icon(type) { return 'mdi-' + this.typeIcons[type]; },
    colour(type) { return this.typeColours[type]; },
  },

  mixins: [typeIcons, typeColours],

}
</script>

