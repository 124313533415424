<template>

  <div class="d-flex">

    <v-chip class="ma-2" color="warning" label text-color="white" v-if="inputs.length==0">
      No Inputs
    </v-chip>

    <v-chip class="ma-2" :color="colour(n.type)" label v-for="n in inputs" v-if="inputs.length>0" >
      <v-icon left>{{icon(n.type)}}</v-icon>
      {{n.name || n.type}}
    </v-chip>

    <div class="mt-2 pt-1"><v-icon>mdi-arrow-right-bold</v-icon></div>

    <v-chip class="ma-2" :color="colour(n.type)" label v-for="n in outputs" v-if="outputs.length>0" >
      <v-icon left>{{icon(n.type)}}</v-icon>
      {{n.name || n.type}}
    </v-chip>

    <v-chip class="ma-2" color="warning" label text-color="white" v-if="outputs.length==0">
      No Outputs
    </v-chip>

  </div>

</template>

<script>
import typeIcons from '@/mixins/typeIcons';
import typeColours from '@/mixins/typeColours';

export default {
  props: {
    journey: { type: Object, required: true },
    uuid: { type: String, required: true },
  },

  // data: () => ({
  // }),

  computed: {
    node() { return this.$store.getters.node(this.uuid); },
    nodes() { return this.$store.getters.nodes; },
    inputs() { return (this.node.inputs || []).map( e => this.findNode(e) ); },
    outputs() { return (this.node.outputs || []).map( e => this.findNode(e) ); },
  },

  methods: {
    findNode(uuid) { return this.nodes.find( e => e.uuid==uuid ); },
    icon(type) { return 'mdi-' + this.typeIcons[type]; },
    colour(type) { return this.typeColours[type]; },
  },

  mixins: [typeIcons, typeColours],

}
</script>

