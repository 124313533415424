<template>
  <div>
    <v-select v-model="node.process" :items="journey.processNames" label="Sub Process"></v-select>
    <v-textarea v-model="node.apiCallParams" label="Parameters" style="font-family: monospace, monospace; font-size: 12px;" />
    <v-alert type="error" dense v-if="!valid">
      Invalid JSON
    </v-alert>
  </div>
</template>

<script>

export default {

  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  data: () => ({
    help: true,
  }),

  computed: {
    valid() {
      let v = true;
      try {
        JSON.parse(this.node.apiCallParams || '{}');
      }
      catch(err) {
        v = false;
      }
      return v;
    },
  },

}
</script>

