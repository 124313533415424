export default {
  data: () => ({
    typeColours: {
      'start': '#FA573C',
      'stop': '#FA573C',

      'annotation': '#FFFFE0',
      'mock': '#3664b0',

      'conditional': '#4986E7',
      'split': '#7BD148',
      'join': '#FFAD46',
      'subProcess': '#16A765',
      'triggerProcess': '',

      'event': '#FAD165',
      'delay': '#B99AFF',
      'userInteraction': '#FF7537',
      'review': '#92E1C0',

      'code': '#bde0fe',
      'apiCall': '#B3DC6C',
      'textMessage': '#C2C2C2',
      'setVariable': '',
      
      'error': '#f822b5',
      'timeout': '#f82299',
    },
  }),
}