<template>
   <v-combobox v-model="node.team" :items="journey.teams" label="Team" />
</template>

<script>

export default {
  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  // data: () => ({
  // }),

  // computed: {
  // },

}
</script>

