export default {
  data: () => ({
    typeDescriptions: {
      'start': 'Start',
      'event': 'Wait for Event',
      'delay': 'Delay',
      'review': 'Manual Review',
      'textMessage': 'Text Message',
      'apiCall': 'API Call',
      'setVariable': 'Variable',
      'conditional': 'Conditional',
      'split': 'Split',
      'join': 'Merge',
      'triggerProcess': 'Sub Process',
      'stop': 'Stop',
      'subProcess': 'Gateway',
      'userInteraction': 'User Interaction',
      'error': 'Error Handler',
      'timeout': 'Timeout Handler',
      'mock': 'Mockup',
      'annotation': 'Annotation',
      'code': 'SASA Code Call',
    },
  }),
}