<template>
  <div>
    <v-text-field number label="Delay" v-model="node.delay" />
    <v-radio-group v-model="node.delayPeriod" row mandatory>
      <v-radio label="Seconds" value="seconds" ></v-radio>
      <v-radio label="Minutes" value="minutes" ></v-radio>
      <v-radio label="Hours" value="hours" ></v-radio>
      <v-radio label="Days" value="days" ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>

export default {
  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  // data: () => ({
  // }),

  // computed: {
  // },

}
</script>

