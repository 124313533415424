<template>
  <div>
    <span :class="icon" v-if="showDisplayName"></span>
    <span v-if="showDisplayName">{{displayName}}</span>
    <br v-if="showDisplayName" />
    <small v-if="subtitle">{{subtitle}}</small>
  </div>
</template>

<script>
import store from '@/store'
import typeIcons from '@/mixins/typeIcons';
import typeDescriptions from '@/mixins/typeDescriptions';

export default {
  props: {
    type: { type: String, required: true },
    uuid: { type: String, required: true },
  },

  // data: () => ({
  // }),

  computed: {
    node() { return store.getters.node(this.uuid); },
    icon() { return 'mdi mdi-' + this.typeIcons[this.type]; },
    displayName() {
      let n = `New ${this.typeDescriptions[this.type]}`;
      if (this.node && this.node.name) {
        n = this.node.name;
      } else {
        if (this.type==='start') { n = 'Start'; }
        if (this.type==='stop') { n = 'Stop'; }
      }
      return n;
    },
    showDisplayName() {
      let r = true;
      if (this.type==='annotation' && this.node && this.node.description) {
        r = this.node.name;
      }
      return r;
    },
    subtitle() {
      let s = undefined;
      if (this.node) {
        if (this.type==='delay' && this.node.delay && this.node.delayPeriod) { s = this.node.delay + ' ' + this.node.delayPeriod; }
        if (this.type==='timeout' && this.node.time && this.node.period) { s = this.node.time + ' ' + this.node.period; }
        if (this.type==='event' && this.node.event) { s = this.node.event; }
        if (this.type==='apiCall' && this.node.apiCall) { s = this.node.apiCall; }
        if (this.type==='setVariable' && this.node.variable) { s = this.node.variable; }
        if (this.type==='review' && this.node.team) { s = this.node.team; }
        if (this.type==='mock' && this.node.description) { s = this.node.description; }
        if (this.type==='annotation' && this.node.description) { s = this.node.description; }
      }
      return s;
    }
  },

  mixins: [typeIcons, typeDescriptions],

}
</script>
