<template>

  <v-card elevation="2" outlined width="100%" >
    <v-card-title>
      <v-icon>mdi-notebook</v-icon>
      <span class="text-capitalize">{{journey.name}}</span>
    </v-card-title>
    <v-card-text>
      <v-text-field label="Name" v-model="journey.name" />
      <v-autocomplete :items="targetTypes" label="Item Type" v-model="journey.targetType"></v-autocomplete>
      <v-combobox v-model="journey.teams" :items="journey.teams" label="Teams" multiple chips ></v-combobox>
      <v-combobox v-model="journey.variables" :items="journey.variables" label="Variables" multiple chips ></v-combobox>
      <v-combobox v-model="journey.apiCalls" :items="apiCalls" label="API Calls" multiple chips ></v-combobox>
      <v-combobox v-model="journey.userActions" :items="journey.userActions" label="User Actions" multiple chips ></v-combobox>
      <v-textarea label="Description" v-model="journey.description" auto-grow />
    </v-card-text>

  </v-card>

</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
  },

  data: () => ({
    journey: undefined,
    targetTypes: [
      { text: 'Leads', value: 'Lead' },
      { text: 'Customers', value: 'Customer' },
      { text: 'Lifecycles', value: 'Lifecycle' },
    ],
    knownApiCalls: {
      'Customer': ['Compliance', 'Credit Check', 'Deduplicator'],
    }
  }),

  computed: {
    callsForType() { return (this.knownApiCalls[this.journey.targetType] || []); },
    apiCalls() { return this.callsForType.concat(this.journey.apiCalls || []) },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.journey = to },
    },
  },

}
</script>
