import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nodes: [],
  },

  mutations: {
    addNode(state, node) { state.nodes.push(node); },
    nodes(state, nodes) { state.nodes = nodes; },
  },

  actions: {
    addNode(context, node) { context.commit('addNode', node); },
    nodes(context, nodes) { context.commit('nodes', nodes); },
  },

  getters: {
    apiKey(state) { return 'TBD'; },
    nodes(state) { return state.nodes; },
    node: (state) => (uuid) => { return state.nodes.find(e => e.uuid===uuid); },
  },
  
});