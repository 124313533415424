<template>
  <v-icon class="mb-1 drag-drawflow" style="cursor: pointer;" :color="iconColor" draggable @dragstart="dragstart" :title="title" :data-node="type">{{icon}}</v-icon>
</template>

<script>
import typeIcons from '@/mixins/typeIcons';
import typeColours from '@/mixins/typeColours';
import typeDescriptions from '@/mixins/typeDescriptions';

export default {
  props: {
    type: { type: String, required: true },
    color: { type: String },
    dragstart: { type: Function, required: true },
  },

  computed: {
    title() { return this.typeDescriptions[this.type]; },
    iconColor() { return this.color || this.typeColours[this.type]; },
    icon() { return `mdi-${this.typeIcons[this.type]}` },
  },

  mixins: [typeIcons, typeColours, typeDescriptions],

}
</script>
