export default {
  data: () => ({
    typeIcons: {
      'start': 'play',
      'event': 'flash',
      'delay': 'clock-outline',
      'review': 'account-multiple-check-outline',
      'textMessage': 'message-text-outline',
      'apiCall': 'robot-happy-outline',
      'setVariable': 'variable',
      'conditional': 'code-tags-check',
      'split': 'arrow-decision',
      'join': 'merge',
      'triggerProcess': 'chart-timeline',
      'stop': 'stop',
      'subProcess': 'circle',
      'userInteraction': 'account-details-outline',
      'error': 'bug',
      'timeout': 'timer-outline',
      'mock': 'movie-edit',
      'annotation': 'text',
      'code': 'xml',
    },
  }),
}