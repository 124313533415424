<template>
  <v-expansion-panels focusable v-model="panel">

    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-icon>{{icon}}</v-icon>
        <span class="text-capitalize">{{name}}</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-text-field label="Name" v-model="node.name" />
      </v-expansion-panel-content>
    </v-expansion-panel>


    <v-expansion-panel :disabled="!showProperties">
      <v-expansion-panel-header>
        Properties
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="showProperties">
        <ApiCall :journey="journey" :node="node" v-if="type==='apiCall'" />
        <Conditional :journey="journey" :node="node" v-if="type==='conditional'" />
        <Delay :journey="journey" :node="node" v-if="type==='delay'" />
        <Event :journey="journey" :node="node" v-if="type==='event'" />
        <Review :journey="journey" :node="node" v-if="type==='review'" />
        <SetVariable :journey="journey" :node="node" v-if="type==='setVariable'" />
        <Split :journey="journey" :node="node" v-if="type==='split'" />
        <TextMessage :journey="journey" :node="node" v-if="type==='textMessage'" />
        <Timeout :journey="journey" :node="node" v-if="type==='timeout'" />
        <TriggerProcess :journey="journey" :node="node" v-if="type==='triggerProcess'" />
        <UserInteraction :journey="journey" :node="node" v-if="type==='userInteraction'" />
        <Mock :journey="journey" :node="node" v-if="type==='mock'" />
        <Annotation :journey="journey" :node="node" v-if="type==='annotation'" />
        <Code :journey="journey" :node="node" v-if="type==='code'" />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header :color="(node.notes ? 'red' : null)">
        Notes
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-textarea label="Notes" v-model="node.notes" />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>
        Help
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ApiCallHelp v-if="type==='apiCall'" />
        <ConditionalHelp v-if="type==='conditional'" />
        <DelayHelp v-if="type==='delay'" />
        <EventHelp v-if="type==='event'" />
        <ReviewHelp v-if="type==='review'" />
        <SetVariableHelp v-if="type==='setVariable'" />
        <SplitHelp v-if="type==='split'" />
        <TextMessageHelp v-if="type==='textMessage'" />
        <TimeoutHelp v-if="type==='timeout'" />
        <TriggerProcessHelp v-if="type==='triggerProcess'" />
        <UserInteractionHelp v-if="type==='userInteraction'" />
      </v-expansion-panel-content>
    </v-expansion-panel>

  </v-expansion-panels>
</template>

<script>
import typeIcons from '@/mixins/typeIcons';
import typeDescriptions from '@/mixins/typeDescriptions';

import ApiCall from '@/components/properties/ApiCall';
import Delay from '@/components/properties/Delay';
import Conditional from '@/components/properties/Conditional';
import Review from '@/components/properties/Review';
import Event from '@/components/properties/Event';
import SetVariable from '@/components/properties/SetVariable';
import Split from '@/components/properties/Split';
import TextMessage from '@/components/properties/TextMessage';
import Timeout from '@/components/properties/Timeout';
import TriggerProcess from '@/components/properties/TriggerProcess';
import UserInteraction from '@/components/properties/UserInteraction';
import Mock from '@/components/properties/Mock';
import Annotation from '@/components/properties/Annotation';
import Code from '@/components/properties/Code';

import ApiCallHelp from '@/components/help/ApiCallHelp';
import DelayHelp from '@/components/help/DelayHelp';
import ConditionalHelp from '@/components/help/ConditionalHelp';
import ReviewHelp from '@/components/help/ReviewHelp';
import EventHelp from '@/components/help/EventHelp';
import SetVariableHelp from '@/components/help/SetVariableHelp';
import SplitHelp from '@/components/help/SplitHelp';
import TextMessageHelp from '@/components/help/TextMessageHelp';
import TimeoutHelp from '@/components/help/TimeoutHelp';
import TriggerProcessHelp from '@/components/help/TriggerProcessHelp';
import UserInteractionHelp from '@/components/help/UserInteractionHelp';
import MockHelp from '@/components/help/MockHelp';

export default {

  components: {
    ApiCall, Conditional, Delay, Event, Review, SetVariable, Split, TextMessage, Timeout, TriggerProcess, UserInteraction, Mock, Annotation, Code,
    ApiCallHelp, ConditionalHelp, DelayHelp, EventHelp, ReviewHelp, SetVariableHelp, SplitHelp, TextMessageHelp, TimeoutHelp, TriggerProcessHelp, UserInteractionHelp, MockHelp,
  },

  props: {
    journey: { type: Object, required: true },
    uuid: { type: String, required: true },
  },

  data: () => ({
    panel: 0,
  }),

  computed: {
    node() { return this.$store.getters.node(this.uuid); },
    type() { return this.node.type },
    icon() { return 'mdi-' + this.typeIcons[this.type]; },
    name() { return ( this.node.name || this.typeDescriptions[this.node.type] ); },
    showProperties() { return !['start', 'stop', 'join','subProcess'].includes(this.type); },
  },

    watch: {
      uuid: {
        immediate: true,
        handler(to) { this.panel = ( this.node.name ? 1 : 0 ); },
      },
    },

  mixins: [typeIcons, typeDescriptions],

}
</script>

