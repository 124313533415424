<template>
  <div>
    <v-radio-group v-model="node.conditionalType" row mandatory>
      <v-radio label="Defined" value="predefined" ></v-radio>
      <v-radio label="Code" value="code" ></v-radio>
      <v-radio label="WF" value="wf" ></v-radio>
    </v-radio-group>
    <v-select v-model="node.conditionalPredefined" :items="predefinedConditionals" v-if="node.conditionalType==='predefined'" />
    <v-textarea v-model="node.conditionalCode" label="Boolean" style="font-family: monospace, monospace;" v-if="node.conditionalType==='code'" />
    <div v-if="node.conditionalType==='wf'">
      <v-select v-model="node.conditionalWfVariable" :items="journey.variables" label="Variable" />
      <v-select v-model="node.conditionalWfConditionale" :items="variableConditionals" label="Conditional" />
      <v-text-field label="Comparator" v-model="node.conditionalWfComparator" />
      <v-select v-model="node.conditionalWfComparatorType" :items="comparatorTypes" label="Comparator Type" />
    </div>

  </div>
</template>

<script>

export default {
  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  data: () => ({
    comparatorTypes: ['Number', 'String'],
  }),

  computed: {

    variableConditionals() {
      return [
        { value: '>', text: '>' },
        { value: '>=', text: '>=' },
        { value: '=', text: '=' },
        { value: '<=', text: '<=' },
        { value: '<', text: '<' },
      ]
    },
    predefinedConditionals() {
      let e = [{ value: 'lead.updated_recently', text: 'Recently Updated' },];
      if (this.journey.targetType==='Customer') {
        e = [
          { value: 'customer.tcs', text: 'T&Cs accepted' },
          { value: 'customer.updated_recently', text: 'Recently Updated' },
          { value: 'customer.deleted', text: 'Deleted' },
          { value: 'customer.suspended:', text: 'Suspended' },
        ];
      } else if (this.journey.targetType==='Lifecycle') {
        e = [
          { value: 'liefcycle.status.created', text: 'Created' },
          { value: 'liefcycle.status.initialised', text: 'Initialised' },
          { value: 'liefcycle.status.starting', text: 'Starting' },
          { value: 'liefcycle.status.started', text: 'Started' },
          { value: 'liefcycle.status.pre_disbursement_accounting', text: 'Pre-disbursement Accounting' },
          { value: 'liefcycle.status.partner_initialised', text: 'Partner Initialised' },
          { value: 'liefcycle.status.doing_accounting', text: 'doing_Accounting' },
          { value: 'liefcycle.status.preliminary_accounting_complete', text: 'Preliminary Accounting_Complete' },
          { value: 'liefcycle.status.scheduling', text: 'Scheduling' },
          { value: 'liefcycle.status.scheduled', text: 'Scheduled' },
          { value: 'liefcycle.status.closed', text: 'Closed' },
          { value: 'liefcycle.status.completing', text: 'Completing' },
          { value: 'liefcycle.status.complete', text: 'Complete' },
          { value: 'liefcycle.status.error', text: 'Error' },
        ];
      }
      return e;
      
    },
  },

}
</script>

