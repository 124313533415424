export default {

  computed: {
    apiKey() { return this.$store.getters.apiKey; },
    remoteUrl() {
      if (process.env.NODE_ENV === 'production') {
        // return 'http://13.235.87.80:3000/api/workflow/';
        return 'https://api.sasa.solutions/api/workflow/';
      } else {
        return 'http://localhost:3000/api/workflow/';
      }
    }
  },

  methods: {

    serializeLookupParameters(params) {
      var str = [];
      for (var p in params)
        if (params.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
        }
      return str.join("&");
    },

    lookup(apiCall, params) {
      return fetch(this.remoteUrl + apiCall + '?' + this.serializeLookupParameters(params), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
          'Authorization': this.apiKey,
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer'
      }).then(response => response.json());
    },


    post(apiCall, params, data) {
      return fetch(this.remoteUrl + apiCall + '?' + this.serializeLookupParameters(params), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
          'Authorization': this.apiKey,
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
      }).then(response => response.json());

    }

  },

}