<template>
  <div>
    <span>{{ journey.targetType }} Events</span>
    <v-autocomplete :items="events" label="Event" v-model="node.event"></v-autocomplete>
  </div>
</template>

<script>

export default {
  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  // data: () => ({
  // }),

  computed: {
    events() {
      let e = [{ value: 'updated', text: 'Lead Updated' },];
      if (this.journey.targetType==='Customer') {
        e = [
          { value: 'customer_updated', text: 'Customer Updated' },
          { value: 'customer_created', text: 'Customer added to system' },
          { value: 'share_purchase', text: 'Customer purchased shares' },
          { value: 'deposit:', text: 'Customer deposit received' },
        ]
      } else if (this.journey.targetType==='Lifecycle') {
        e = [
          { value: 'updated', text: 'Lifecycle Updated' },
          { value: 'initialised', text: 'Initialised' }, { value: 'disbursed', text: 'Disbursed' }, { value: 'receipted', text: 'Receipted' }, { value: 'receipting_concluded', text: 'Receipting concluded' }, { value: 'arrears', text: 'Arrears' }, { value: 'out_of_arrears', text: 'Out of arrears' }, { value: 'settled', text: 'Settled' }, { value: 'on_due_date', text: 'on due date' }, { value: 'one_day_after_due_date', text: 'One day after due date' }, { value: 'three_days_before_due_date', text: 'Three days before due date' }, { value: 'six_hours_before_due', text: 'Six hours before due' }, { value: 'one_day_before_due', text: 'One day before due' }, { value: 'two_days_before_due', text: 'Two days before due' }, { value: 'three_days_before_due', text: 'Three days before due' }, { value: 'seven_days_before_due', text: 'Seven days before due' }, { value: 'saving_welcome', text: 'Saving welcome' }, { value: 'loan_strike_fail', text: 'Loan strike fail' }, { value: 'loan_strike_partial', text: 'Loan strike partial' }, { value: 'fail_retry', text: 'Fail retry' }, { value: 'retry_debit', text: 'Retry debit' }, { value: 'loan_disbursed', text: 'Loan disbursed' }, { value: 'loan_welcome', text: 'Loan welcome' }, { value: 'saving_deposit', text: 'Saving deposit' }, { value: 'saving_withdrawal', text: 'Saving withdrawal' }, { value: 'loan_extended_penality', text: 'Loan extended penality' }, { value: 'three_day_after_downpayment', text: 'Three day after downpayment' }, { value: 'five_day_after_downpayment', text: 'Five day after downpayment' }, { value: 'thankyou_repayment', text: 'Repayment thank you' },
        ]
      }
      return e;
    }
  },

}
</script>

