<template>
  <div>
    <v-select v-model="node.variable" :items="journey.variables" label="Variable" />
    <v-radio-group v-model="node.action" row mandatory>
      <v-radio label="Set" value="set" ></v-radio>
      <v-radio label="Random" value="random" ></v-radio>
      <v-radio label="Increment" value="increment" ></v-radio>
      <v-radio label="Decrement" value="decrement" ></v-radio>
      <v-radio label="Multiply" value="multiply" ></v-radio>
    </v-radio-group>
    <v-text-field v-model="node.value" single-line type="number" :label="label" :hint="label" persistent-hint :disabled="node.action==='random'" />
   </div>
</template>

<script>

export default {
  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  // data: () => ({
  // }),

  computed: {
    label() {
      let r = '';
      if (this.node.action==='increment') { r = 'Increment by'; }
      if (this.node.action==='decrement') { r = 'Decrement by'; }
      if (this.node.action==='multiply') { r = 'Multiply by'; }
      if (this.node.action==='set') { r = 'Set to'; }
      if (this.node.action==='random') { r = 'Random number between 0 and 99 (inclusive)'; }
      return r;
    }
  },

}
</script>

