<template>
  <div>
    <v-textarea label="Template" v-model="node.textMessage" style="font-family: monospace, monospace;" />
    <v-switch v-model="node.textMessageOnce" :label="(node.textMessageOnce ? 'Send only once' : 'Send repeatedly')" ></v-switch>
  </div>
</template>

<script>

export default {
  props: {
    journey: { type: Object, required: true },
    node: { type: Object, required: true },
  },

  // data: () => ({
  // }),

  // computed: {
  // },

}
</script>

